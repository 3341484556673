<template>
  <div v-myTitle :data-title="textTitle">
    <div class="explain">
      <!--    <h1 class="title">TACK</h1>-->
      <h1 class="title" style="margin-top: 100px">{{ this.$title }}</h1>
      <div class="scoreTitle" v-if="show == 'true'">
        <div class="content">矩阵说明：</div>
        <div class="content" style="margin-top: 10px;text-indent: 28px">
          {{ this.$title }}旨在通过相关{{ this.$project }}技能的重要程度及目前{{
            this.$project
          }}人员的技能水准，分析{{
            this.$project
          }}人员存在哪些具体的培训及发展需求，帮助{{
            this.$project
          }}人员及其上司明确须协力发展的方向。
        </div>
      </div>
      <div class="scoreTitle" v-if="show == 'false'">
        <div class="content">评分说明：</div>
        <div class="content" style="margin-top: 10px; text-indent: 28px">
          每题即为相关{{
            this.$project
          }}技能项，请您根据目前实际情况，评价您自身或您的下属（被评人姓名显示于页面左上方）在该项中的技能水准。
        </div>
        <div class="content" style="margin-top: 10px; text-indent: 28px">
          点击【开始评分】进行答题。请一次性完成评分，中途退出不保留之前记录。
        </div>
        <div class="content" style="margin-top: 10px">
          注：贵司组织方已完成相关技能水准对于当前职位的重要性评分。
        </div>
      </div>
      <button @click="next" v-if="show == 'true'" class="btn">下一步</button>
      <div v-if="show == 'false'">
        <div v-if="userInfo.times == 1">
          <button v-if="results.length == 0 || results[0].status == 0" @click="start(1)" class="btn">开始评分</button>
          <button v-else @click="goCobweb(1)" class="btn">查看评分</button>
        </div>
        <div v-if="userInfo.times == 2">
          <div v-if="results.length == 0 || results[0].status == 0">
            <button @click="start(1)" class="btn">开始评分</button>
          </div>
          <div v-else-if="results.length == 1 || results[1].status == 0">
            <button @click="goCobweb(1)" class="btn">查看第1次评分</button>
            <button @click="start(2)" class="btn">开始第2次评分</button>
          </div>
          <div v-else-if="results.length == 2">
            <button @click="goCobweb(1)" class="btn">查看第1次评分</button>
            <button @click="goCobweb(2)" class="btn">查看第2次评分</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {storeResult, getResult} from '@/request/api'

export default {
  name: "Explain",
  data() {
    return {
      textTitle: this.$title,
      show: 'true',
      userInfo: {},
      staffInfo: {},
      results: {},
    };
  },
  created() {
    this.userInfo = this.$store.state.userInfo;
    if (!this.$store.state.userInfo) {
      this.$router.replace("/");
      return false;
    }
    this.getResult();
    if (this.$route.query.show) {
      this.show = this.$route.query.show
    }

  },
  methods: {
    next() {
      this.show = 'false';
    },
    start(times) {
      console.log(this.userInfo.type, 'type')
      let staffId = 0;
      if (this.userInfo.type == 2) {
        let staffInfo = this.$store.state.staffInfo;
        this.staffInfo = staffInfo
        staffId = staffInfo.id
        times = staffInfo.times
      }
      storeResult({
        staff_id: staffId,
        times: times
      }).then((res) => {
        console.log(res.data, 'result_id')
        this.$store.commit("setResultId", res.data);
      });
      this.$router.replace({
        path: '/exam',
        query: {
          times: times
        }
      });
    },
    goCobweb(times) {
      this.$router.push({
        path: '/cobweb',
        query: {
          times: times
        }
      })
    },
    getResult() {
      getResult({staff_id: this.staffInfo.id}).then(res => {
        console.log(res.data)
        this.results = res.data
      })
    }
  },
};
</script>

<style>
.explain {
  background: url("../assets/img/index1.jpg");
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  background-size: cover;
  position: absolute;
}

.title {
  color: #0089c4;
  margin: 0;
}

.scoreTitle {
  width: 80%;
  height: 150px;
  margin: 24px auto;
  line-height: 22px;
  text-align: left;
}

.btn {
  height: 30px;
  width: max-content;
  padding: 2px 10px;
  margin-top: 120px;
  margin-left: 20px;
  margin-right: 20px;
  border-radius: 5px;
  color: rgb(255, 255, 255);
  border: 1px solid rgb(255, 255, 255);
  box-shadow: 0 0 0 2px rgb(255, 255, 255), 0 0 5px 3px rgb(37, 161, 221);
  background-image: linear-gradient(#00B3F1, #00A3EF);
}

.content {
  font-size: 14px;
}
</style>